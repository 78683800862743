.titulo-2 {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin: 0;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .titulo-2 {
    font-size: calc(18px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  .titulo-2 {
    font-size: 20px;
  }
}
