
.historia-de-vida {
  display: flex;
  flex-direction: column;
  padding: 2vh 2vw;
}

.editor-historia-de-vida,
.wrapper-historia-de-vida {
  background-color: #fff;
  height: 90%;
}

.wrapper-historia-de-vida {
  flex-grow: 1;
  height: 250px;
  max-height: 250px;
  max-width: 900px;
}

.DraftEditor-editorContainer, 
.DraftEditor-root {

  max-height: 100%;
  height: 100%;
}

.DraftEditor-editorContainer {
  overflow: auto;
}
