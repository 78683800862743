.sub-header {
  margin: 40px 0;
  align-items: flex-end;
}

.sub-header hr {
  margin-bottom: 0;
}

.sub-header .row {
  margin: 10px;
}

.title {
  color: #929c7c;
}
